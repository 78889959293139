import React from 'react'
import { Link } from 'gatsby'
//import pic02 from '../assets/images/that-fg.png'
import pic08 from '../assets/images/11-Industries.png'
//import { Link as ScrollLink } from 'react-scroll'
import Fade from 'react-reveal/Fade'

const IndustriesCallOut = props => (
  <div
    id="industries"
    className="banner__container style1 top"
  >

    <Fade big>
      <div className="content">
        <div className="container">
          <div className="row">
            <div className="col-4 col-12-medium">
                <span className="image fit">
                        <img src={pic08} alt="" />
                </span>
            </div>
            <div className="col-8 col-12-medium">
              <header>
              <h2>FOR INDUSTRIES</h2>     
              <h4>Why Invest in Digital Marketing?</h4>            
                <p>                
                    The way the world has changed towards digitalization, Digital Marketing is helping
                    businesses grow and bring in remarkable revenue.
                </p>
                <Link to="/industries" className="button primary" >Keep Reading</Link>
                
              </header>
            </div>
          </div>
        </div>
      </div>
    </Fade>

  </div>
)
export default React.memo(IndustriesCallOut)
