import React from 'react'
import { Helmet } from 'react-helmet'
import Layout from '../components/layout'
import Banner from '../components/Banner'
import One from '../components/One'
import Two from '../components/Two'
import IndustriesCallOut from '../components/IndustriesCallOut'
import Foot  from '../components/Foot'


class Home extends React.Component {
  render() {
    return (
      <Layout location="/">
        <Helmet
          htmlAttributes={{ lang: 'en' }}
          title="Bulls i Marketing"
          meta={[
            { name: 'description', content: 'Use B2B Search Marketing to reach decision-makers and businesses that want to partner with you' },
            { name: 'keywords', content: 'B2B EMAIL MARKETING, B2B, EMAIL MARKETING' },
          ]}
        ></Helmet>

        <Banner />

        <One />      

        <Two />
        
        <IndustriesCallOut />

        <Foot />

      </Layout>
    )
  }
}

export default Home
