import React from 'react'
import Fade from 'react-reveal/Fade'
import pic02 from '../assets/images/pic05.jpg'
import { Link as ScrollLink } from 'react-scroll'

import advertising from '../assets/images/AdvertisingPNG.jpg'
import seo from '../assets/images/seo.png'
import ppc from '../assets/images/ppc.png'
import call from '../assets/images/call.png'
import social from '../assets/images/social.png'
import mail from '../assets/images/mail.png'

const Two = props => (
  
    <section
    id="two"
    className="spotlight style1 special fade-up top inactive"
    style={{ backgroundImage: `url(${pic02})`,
  }}
  >

    <Fade big>
    <div className="content white"
          style={{
          backgroundColor: "rgba(255, 255, 255, 0.67)",
          backdropFilter: "blur(52px)"
        }}>
      <div className="container">

        <header className="major">
          <h2>OUR TOOLS TO YOUR SUCCESS:</h2>
          
        </header>
        <div className="box alt">
          <div className="row gtr-uniform">
            <section className="col-4 col-6-medium col-12-xsmall"> 


              <a href="/introduction" className="primary">
              <span className="image circle">
                  <img src={advertising} alt="" />
              </span>
              
                
                <h3>Advertising Agency </h3>
              </a>
              <p>
                As an Advertising Agency, we have only one mission: to ensure client satisfaction. A happy client is a busy customer and nothing else matters. It takes a lot of dedication, energy leadership, and confidence to create productive ad campaigns with measurable results. We just expect this from our creative analytical and mastermind professionals, and you expect nothing less from our advertising agency. 
              </p>
                <a href="/introduction" className="button primary">Learn More</a>
            </section>

            <section className="col-4 col-6-medium col-12-xsmall">
              <a href="/seo" className="primary">
                <span className="image circle">
                    <img src={seo} alt="" />
                </span>
                <h3>SEO Company </h3>
              </a>

                <h4>Step 1: Developing the Strategy</h4>
                <p>
                Our detailed initial analysis reports build the foundation for a strong search engine success possible and determine the best way of getting a return on your investment.
                </p>
                <h4>Step 2: Ongoing Search Engine Optimization</h4>
                <p>        
                  We believe SEO is the single best investment most firms can make.
                </p>
                <a href="/seo" className="button primary">Learn More</a>
            </section>


            <section className="col-4 col-6-medium col-12-xsmall">
                <a href="/ppc" className="primary">
                <span className="image circle">
                    <img src={ppc} alt="" />
                </span>
                  <h3>PPC Management Company</h3>
                </a>
              <p> Our strategy is all-inclusive, and we don’t limit ourselves to your account only. We make sure that tracking analytics are perfectly set up, so your landing pages help to boost up your quality scores (which eventually lowers your cost per click), and we also share our view on how we can better convert your clients to get the best ROI.</p>
              <a href="/ppc" className="button primary">Learn More</a>
            </section>

            <section className="col-4 col-6-medium col-12-xsmall">
              <a href="/reputation-management" className="primary">
                <span className="image circle">
                    <img src={social} alt="" />
                </span>
                <h3>Reputation Management Company</h3>
              </a>
              <h4>Step 1: Research</h4>
              <p>
              Our detailed initial analysis reports build the foundation for a strong search engine reputation management success possible and determine the best way of getting a return on your investment.
              </p>
              <h4>Step 2: Develop Your Strategic Internet Marketing Plan (SIMP)</h4>
              <p>        
              Based on our data collection, we develop a custom SIMP that determines our way of dominating the rankings in an organic search engine.
              </p>
              <a href="/reputation-management" className="button primary">Learn More</a>
            </section>

            <section className="col-4 col-6-medium col-12-xsmall">
              <a href="/smm" className="primary">
                <span className="image circle">
                    <img src={call} alt="" />
                </span>
                <h3>CallTraxPlus</h3>
              </a>
              <h4>Ad Driven Campaigns</h4>
              <p>Integrate Accounts
              Setup Pixel and Analytics Conversion Tracking Build Remarketing Campaigns
              Product Campaigns
              Awareness Campaigns
              </p>
              <h4>Organic Driven Campaigns </h4>
              <p>Integrate Accounts Awareness/Traffic Campaign Develop Brand Voice and Tone Content Curation/Repurposing Regular Posting
              Community Engagement Weekly Reporting
              </p>   
              <a href="/smm" className="button primary">Learn More</a>
            </section>

            <section className="col-4 col-6-medium col-12-xsmall">
            <a href="/email-marketing" className="primary">
                <span className="image circle">
                    <img src={mail} alt="" />
                </span>
              <h3>MailTraxPlus</h3>
            </a>
              <p>
              Email Marketing Strategy Development, Guidance, Content Creation and more.
              </p>
              <p>You’ll love the matching and mixing of MailTrax Plus’s features for templates, functions, and integrations to suit your demands.</p>
              <a href="/email-marketing" className="button primary">Learn More</a>
            </section>

            <section style={{ display: "none", listStyle: "none"}} className="col-4 col-6-medium col-12-xsmall">  
           </section>
            <section style={{ display: "none", listStyle: "none"}} className="col-4 col-6-medium col-12-xsmall">
            <a href="/introduction" className="primary">
              <span className="icon solid alt major fa-at"></span>
              <h3>Bulls i Marketing! Company </h3>
            </a>
              <p>
              </p>
              <a href="/introduction" className="button primary">Learn More</a>
          </section>
          <section style={{ display: "none", listStyle: "none"}} className="col-4 col-6-medium col-12-xsmall">
          </section>
           
          </div>
        </div>
        <footer className="major">
        <h2></h2>
        <p></p>
        
          <ul style={{ display: "none", listStyle: "none"}}>
            <li>1. Competitor Keyword Research</li>
            <li>2. Relevant Ad Copy</li>
            <li>3. Split Testing Ads</li>
            <li>4. Continuous Research and Optimization</li>
          </ul>
        

        </footer>
        </div>
      </div>
      </Fade>
      <ScrollLink
      to="industries"
      className="goto-next"
      activeClass="active"
      smooth={true}
      offset={50}
      duration={1500}
      spy={true}
    >
      Next
    </ScrollLink>
    </section>
  
)

export default React.memo(Two)
