import React from 'react'
import { Link } from 'gatsby'
import pic02 from '../assets/images/that-fg.png'
import pic08 from '../assets/images/istockphoto-1158762452-1024x1024.jpg'
//import { Link as ScrollLink } from 'react-scroll'
import Fade from 'react-reveal/Fade'

const One = props => (
  <div
    id="one"
    className="banner__container style1 top"
    style={{ backgroundImage: `url(${pic02})` }}
  >

    <Fade big>
      <div className="content">
        <div className="container">
          <div className="row">
            <div className="col-4 col-12-medium">
              <header>
              <h2>INTRODUCTION</h2>                 
                <p>
                Bulls i Marketing Company has been giving custom marketing solutions, Internet marketing strategies, and personalized search engine marketing strategies for more than 15 years, and we value our client connections. Bulls i Marketing Company's licensed "search engineers," have come out on top in growing profoundly effective, exclusive search engine optimization, and marketing techniques Bulls i Marketing have put several driving enterprises in the top rankings of the world's significant search properties.
                </p>
                <Link to="/introduction" className="button primary" >Keep Reading</Link>
                
              </header>
            </div>
            <div className="col-4 col-12-medium">
            <h4>WE’RE A COMPLETE SOLUTION FOR YOUR INTERNET MARKETING</h4>
            <p>
              Our company tends to provide as easy and convenient as possible to solutions to get their needs fulfilled in a “single source” environment. Bulls i Marketing Company is our primary business organization for Web projects partnering with all types of other organizations for collaboration. 
            </p>

            </div>
            <div className="col-4 col-12-medium">
            <span className="image fit">
                    <img src={pic08} alt="" />
            </span>
            </div>
          </div>

        </div>

      </div>
    </Fade>

  </div>
)
export default React.memo(One)
